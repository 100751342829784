import * as React from "react";
import { CheckIcon } from "@heroicons/react/outline";

const features = [
  {
    name: "Why should I use Lingua Fonica?",
    description:
      "You should use Lingua Fonica because we strive to be the best online language learning community. We care about your progress and are ready to help you. We focus on the quality of tutors and the content of our lessons to improve your learning experience!",
  },
  {
    name: "Why should I trust Lingua Fonica?",
    description:
      "Lingua Fonica is in partnership with Conversation Piece, a company that has provided lessons with verified tutors for over 30 years!",
  },
  {
    name: "How do I find a tutor?",
    description:
      "You can find a tutor by going to our homepage and clicking the “learn” button. Or, you can go to the page of the language that you want to learn and click “find a tutor.” These links will take you to our sign up page where you can submit information about your learning needs and schedule before you prepay for your desired number of lessons. Our admins will then connect you to the tutor best suited to your learning and schedule your first lesson.",
  },
  {
    name: "How do I apply to be a tutor?",
    description:
      "Click the teach button on our homepage and fill out the application. You’ll need your CV or resume and the contact information for 3 references. Our admins will contact you if we would like you to interview.",
  },
  {
    name: "How can I report an issue?",
    description: "You can report an issue that you encounter by going to our ",
    link: (
      <a
        className="underline font-medium text-indigo-600 hover:text-indigo-500"
        href="/contact-us"
      >
        contact us page.
      </a>
    ),
  },
  {
    name: "Can I take multiple lessons?",
    description:
      "You can sign up for as many lessons in a week as you can handle. Most students do one one-hour lesson per week, but If you would like a more intensive course, you can do more.And if you would like to take lessons in multiple languages at the same time, we can accommodate that as well. We do expect students to be respectful of our tutors by being prepared for class and attending lessons as scheduled.",
  },
  {
    name: "How to use Lingua Fonica?",
    description:
      "To use Lingua Fonica, you must create an account and let us know your learning needs. Once you have chosen your language, proficiency level, and preferred times, you will prepay for the number of lessons that you want to complete. Our admins will then match your needs with the right tutor for you and schedule the first lesson.",
  },
];

export default function FaqPage() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h1 className="text-base font-semibold text-indigo-600 uppercase tracking-wide">
            All your most common questions
          </h1>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">
            All in one place
          </p>
          <p className="mt-4 text-lg text-gray-500">{/* sub-title */}</p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          {/* sm:grid-cols and sm:grid-rows must be adjusted if more Q&A are added and break the display */}
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-5 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green-500"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">
                  {feature.description}
                  {feature.link ? feature.link : null}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
