import * as React from "react";
import FaqPage from "@/components/FaqPage";

import Layout from "../components/Layout";
import { createSeoData } from "../utils/page";

export default function FAQPage() {
  const seo = createSeoData({
    title: "Lingua Fonica FAQ",
    description:
      "Browse our frequently asked questions about real time online language courses to see if we have answered your question already.",
  });

  return (
    <Layout seo={seo}>
      <FaqPage />
    </Layout>
  );
}
